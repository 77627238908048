'use client'

import { AlertOctagon } from 'lucide-react'
import { Button } from 'ui'
import { useCatchError } from '@/shared/hooks'

const Error = ({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) => {
  useCatchError(error)

  return (
    <div className="grid min-h-screen place-content-center">
      <div className="flex flex-col items-center justify-center gap-4 p-4 rounded-lg w-max">
        <AlertOctagon className="text-destructive" size={52} />
        <h2>Algo salió mal!</h2>
        <div className="flex items-center gap-3">
          <Button onClick={reset} type="button">
            Intentar de nuevo
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Error
